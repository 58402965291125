<template>
    <div>
        <a-carousel autoplay>
            <template v-for="image in companyImgList">
                <template v-if="image === '/companyindex/wzqy1.jpg'">
                    <a-image :src="image" :preview="false" @click="handleOk" class="a-image" />
                </template>
                <template v-else>
                    <a-image :src="image" :preview="false" />
                </template>
            </template>
        </a-carousel>
        <div class="jeecg-basic-header">
            <a-row>
                <vue3-seamless-scroll :list="dataSource" step="0.4" class="seamless-warp" hover-stop="true" hover="true"
                    style="width: 100%;">
                    <a-table rowKey="id" :bordered="false" :columns="Columns" :data-source="dataSource"
                        :pagination="false" :loading="loading" style="width: 100%;" :showHeader="false">
                        <template #creatorCompanyName="{ text, index, record }">
                            <a-space :size="12" align="baseline">
                                <h3 style="width: 200px">{{ record.creatorCompanyName || '--' }}</h3>
                                <a-tag color="orange">{{ record.revenueScale_dictText }}</a-tag>
                                <a-divider type="vertical" />
                                <p>发布日期：{{ record.orderDate || '--' }}</p>
                                <a-divider type="vertical" />
                                <a-tag color="blue">{{ record.addressName }}</a-tag>
                                <a-tag color="#87d068">{{ record.hangye }}</a-tag>
                            </a-space>
                            <div>
                                <a-tag style="width: 110px;text-align: center" color="green">{{
                record.declareType_dictText
            }}</a-tag>
                                <a-divider type="vertical" />
                                <span>代理期间：{{ record.accountingStartTime }}&nbsp;-&nbsp;{{ record.accountingEndTime
                                    }}</span>
                                <a-divider type="vertical" />
                                <span v-for="(item, index) in record.serviceItem_dictText" :key="index">{{ item }}<span
                                        v-if="index <= record.serviceItem_dictText.length - 2">&nbsp;|&nbsp;</span></span>
                            </div>
                        </template>

                        <template #declareType_dictText="{ text, index, record }">
                            <div class="y-flex">
                                <div>
                                    <!-- <h3 style="color: red">￥{{ record.amount / 100 }}</h3> -->
                                    <template v-if="record.totalAmount != record.amount">
                                        <h3 style="color:red; text-decoration: line-through;">
                                            ¥ {{ Math.round(record.totalAmount / 100) }}
                                        </h3>
                                        <h3 style="color:red;">
                                            (优惠价: ¥ {{ Math.round(record.amount / 100) }})
                                        </h3>
                                    </template>
                                    <template v-else>
                                        <h3 style="color:red;">
                                            ¥ {{ Math.round(record.totalAmount / 100) }}
                                        </h3>
                                    </template>
                                    <div>已投{{ record.deliveryNum ? record.deliveryNum : 0 }}人</div>
                                </div>
                                <div style="text-align: center">
                                    <a-button style="float: right" type="primary" shape="round"
                                        @click="onClick">投递</a-button>
                                </div>
                            </div>
                        </template>
                    </a-table>
                </vue3-seamless-scroll>
            </a-row>
        </div>
    </div>
</template>

<script setup>
import { ref, onBeforeMount, computed } from 'vue';
import { ExclamationCircleOutlined } from '@ant-design/icons-vue';
import { Columns, companyImgList } from '../components/data'
import { getCompanyList } from '../api/api'
import { Modal } from 'ant-design-vue';
import { h } from 'vue';
const dataSource = ref([]);
const loading = ref(false);
onBeforeMount(() => {
    initData();
})

async function initData() {
    loading.value = true
    const res = await getCompanyList()
    loading.value = false
    console.log("res", res)
    const { success, result } = res
    if (success) {
        result.records.forEach((item) => {
            item.serviceItem_dictText = item.serviceItem_dictText ? item.serviceItem_dictText.split(',') : '';
            item.serviceItem = item.serviceItem ? item.serviceItem.split(',') : [];
            item.bool = true;
        });
        dataSource.value = result.records
    }
}

function onClick() {
    Modal.confirm({
        title: '请登录平台再进行后续操作',
        icon: h(ExclamationCircleOutlined),
        okText: '去登录',
        cancelText: '暂不登录',
        onOk() {
            console.log('OK');
            window.open('https://www.shangbian.net/v3/', '_blank')
        },
        onCancel() {
            console.log('Cancel');
        },
    });
}

function handleOk() {
    window.open('https://www.shangbian.net/v3/', '_blank')
}
</script>

<style>
.jeecg-basic-header {
    margin: 2rem;
}

.topback {
    background-color: #ffffff;
    padding: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.y-flex {
    justify-content: space-between;
    align-items: center;
    display: flex;
}

.scroll_table {
    margin: 1rem;
}

.seamless-warp {
    padding: 2rem;
    height: 42vh;
    overflow: hidden;
    width: 100%;
}

.a-image {
    cursor: pointer !important;
}
</style>