import request from "../utils/http/request";

const Api = {
    queryDzEffectRecordPage: '/daizhang/api/public/queryDzEffectRecordPage',
    list: '/daizhang/api/public/list',
    effectDzPreferential: '/daizhang/dzPreferential/effectDzPreferential',
    receiveDzPreferential: '/daizhang/dzPreferential/receiveDzPreferential',
    sms: '/jeecg-boot/sys/sms'
}

/**
 * 查询列表
 * @param params
 */
export const getList = (params) => {
    return request({
        url: Api.queryDzEffectRecordPage,
        method: 'post',
        data: params,
        headers: { 'content-type': 'application/x-www-form-urlencoded' }
    })
};

/**
 * 领取活动接口
 * @param {*} params 
 * @returns 
 */
export const receiveActive = (params) => {
    return request.get(Api.receiveDzPreferential + "?id=" + params.id + "&phone=" + params.phone);
}

/**
 * 查询企业列表
 * @param params
 */
export const getCompanyList = (params) => {
    return request.get(Api.list, params);
};

/**
 * 获取上架中活动
 * @param {*} params 
 * @returns 
 */
export const getActivity = (params) => {
    return request.get(Api.effectDzPreferential, params)
}

/**
 * 获取短信验证码
 * @param {*} params 
 * @returns 
 */
export const getSmsCode = (params) => {
    return request.post(Api.sms, params)
}