<template>
    <div class="sider">
        <!-- <a-row>
            公众
        </a-row>
        <a-row></a-row>
        <a-row></a-row>
        <a-row></a-row> -->
        <a-card :bodyStyle="{ padding: 0 }">
            <template v-for="item in siderList">
                <a-popover placement="left">
                    <template #content>
                        <a-image v-if="item.type == 'img'" :src="item.img" :width="item.width">
                            <template #previewMask>
                                <a-space :size="3">
                                    <EyeOutlined />
                                    <span>预览</span>
                                </a-space>
                            </template>
                        </a-image>
                        <siderContact v-else-if="item.type == 'text'" />
                    </template>
                    <a-card-grid style="width: 100%; text-align: center; cursor: pointer;" @click="onClick(item.img)">
                        <div>
                            <a-image :preview="false" :width="28" :src="item.url" />
                            <span v-if="item.img" class="text">{{ item.label }}</span>
                            <span v-else class="text2">{{ item.label }}</span>
                        </div>
                    </a-card-grid>
                </a-popover>
            </template>
        </a-card>

        <div ref="globalModal">
            <a-modal v-model:visible="open" :footer="null" :getContainer="() => $refs.globalModal"
                :maskClosable="false">
                <template #closeIcon>
                    <close-circle-outlined style="color: white; font-size: 1.5rem; margin-left: 2.5rem;" />
                </template>
                <a-image style="cursor: pointer;" :preview="false" :src="imgUrl" @click="onClickToReceive" />
            </a-modal>
        </div>
        <sideModal ref="modal" />
    </div>
</template>

<script setup>
import { ref, onBeforeMount } from "vue";
import { EyeOutlined } from '@ant-design/icons-vue';
import { CloseCircleOutlined } from '@ant-design/icons-vue';
import { getActivity } from '@/api/api.js'
import sideModal from './sideModal.vue'

const open = ref(false)
const modal = ref(null)
const imgUrl = ref('')
const title = ref('')
const id = ref('')
const siderList = ref([
    {
        url: '/companyindex/lxwmmini.png',
        label: '联系我们',
        img: '/companyindex/lxwm2.png',
        type: 'img',
        width: 370
    },
    {
        url: '/companyindex/gzhmini.png',
        label: '公众号',
        img: '/companyindex/gzh.jpg',
        type: 'img',
        width: 150
    },
    {
        url: '/companyindex/xcxmini.png',
        label: '小程序',
        img: '/companyindex/xcx.jpg',
        type: 'img',
        width: 150
    },
    {
        url: '/companyindex/wxkfmini.png',
        label: '客服微信',
        img: '/companyindex/kfql.png',
        type: 'img',
        width: 150
    }
])

onBeforeMount(() => {
    getDiscount();
})

function onClick(param) {
    if (!param) {
        open.value = true
    }
}

async function getDiscount() {
    let params = { type: 'dzActivityType' }
    const res = await getActivity(params)
    console.log("Res", res)
    if (res.success) {
        const { result } = res
        if (result) {
            siderList.value.push({
                url: '/companyindex/yhj2.png',
                label: '首单优惠',
                img: null
            })
            // open.value = true
            imgUrl.value = result.picture
            title.value = result.title
            id.value = result.id
        }
    }
}

function onClickToReceive() {
    console.log("sss")
    open.value = false
    let record = {
        title: title.value,
        id: id.value
    }
    modal.value.showModal(record)
}

</script>

<style scoped>
.sider {
    position: fixed;
    left: 0rem;
    z-index: 999;
    top: 15rem;
    width: 4.8rem;
    overflow: hidden;
    border: 1px solid #eee;
    border-right: none;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
}

.ant-card-grid {
    padding: 0.4rem !important;
}

.text {
    display: block;
    color: #333;
    font-size: 15px;
    text-align: center;
    transform: scale(0.9);
    font-weight: normal;
    cursor: pointer;
    margin-bottom: 0px;
    margin-top: 0px;
}

.text2 {
    display: block;
    color: #e4780a;
    font-size: 15px;
    text-align: center;
    transform: scale(0.9);
    font-weight: normal;
    cursor: pointer;
    margin-bottom: 0px;
    margin-top: 0px;
}

.discount {
    position: fixed;
    right: 0rem;
    z-index: 999;
    top: 32rem;
    overflow: hidden;
}

:deep(.ant-modal-content) {
    background-color: rgba(255, 255, 255, 0) !important;
    box-shadow: none !important;
}

:deep(.ant-popover-inner-content) {
    padding: 0;
}
</style>