import { createApp } from 'vue'
import App from './App.vue'
import Antd from 'ant-design-vue'
import router from './router/index'
import 'ant-design-vue/dist/antd.css';
import '@/assets/style.css'
import vue3SeamlessScroll from "vue3-seamless-scroll"; // 循环滚动

const app = createApp(App);
app.use(Antd)
app.use(router)
app.use(vue3SeamlessScroll)
// 挂载应用
app.mount('#app', true);

