<template>
    <a-row>
        <a-typography-text style="text-align: center; width: 100%;" strong>Copyright © 2021 河南商变网络科技有限公司</a-typography-text>
    </a-row>
    <a-row>
        <a-typography-text style="text-align: center; width: 100%;"  strong><div style="cursor: pointer;" onclick="window.open('https://beian.miit.gov.cn/')">豫ICP备2021032528号-2</div></a-typography-text>
    </a-row>
</template>

<script>
</script>