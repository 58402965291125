import axios from "axios";
import { Modal, notification } from 'ant-design-vue'

const request = axios.create({
  // 此处的 '/api' 和 vite.config.js 的配置相关联
  baseURL: "",
  timeout: 50000,
  requestOptions: {
    withToken: true,
  },
});

// 数据请求拦截
request.interceptors.request.use(
  // 1. 返回config对象
  // 2. 可以设置携带 token 信息
  (config) => {
    const token = window.localStorage.getItem("access_token");
    token &&
      config?.requestOptions?.withToken &&
      (config.headers.token = token);
    return config;
  },
  (err) => {
    return Promise.reject(err);
  }
);

// 返回响应数据拦截
request.interceptors.response.use(
  (response) => {
    //   console.log("request.js打印返回信息" , response);
    // 简化返回数据
    if (response.data.code == "200" || response.data.code == "0" || response.data.code == "1013") {
      return Promise.resolve(response.data);
    }
  },
  // 错误执行
  (error) => {
    console.log("错误信息", error);
    if (error.response) {
      let data = error.response.data
      if (error.response.status) {
        switch (error.response.status) {
          case 403:
            notification.error({ message: '系统提示', description: '拒绝访问', duration: 4 })
            break
          case 404:
            notification.error({ message: '系统提示', description: '很抱歉，资源未找到!', duration: 4 })
            break;
          case 500:
            console.log("------error.response------", error.response)
            notification.error({ message: '系统提示', description: "服务端异常", duration: 4, key: 500 })
            break;
          // 还可以自己添加其他状态码
          default:
            notification.error({
              message: '系统提示',
              description: data.message,
              duration: 4
            })
            break;
        }
      }
    }
    return Promise.reject(error);
  }
);

// 暴露对象
export default request;