<template>
    <div>
        <a-carousel autoplay>
            <template v-for="image in imageList">
                <template v-if="image === '/companyindex/wzkj1.jpg'">
                    <a-image :src="image" :preview="false" @click="handleOk" class="a-image" />
                </template>
                <template v-else>
                    <a-image :src="image" :preview="false" />
                </template>
            </template>
        </a-carousel>
        <div class="jeecg-basic-header">
            <a-row style="margin-bottom: 1.5rem; margin-top: 1rem">
                <a-descriptions>
                    <a-descriptions-item :span="3" label="申报方式">
                        <a-radio-group v-model:value="declarationMethod" :options="plainOptions" @change="onChange" />
                        <a-tooltip>
                            <template #title>零申报无费用：税务系统进行零申报，且不提供任何发票和内部单据。
                                零申报有费用：税务系统进行零申报，提供少量发票或内部单据需进行账务处理。
                            </template>
                            <question-circle-outlined />
                        </a-tooltip>
                    </a-descriptions-item>
                    <a-descriptions-item :span="3" label="服务项目" style="padding-bottom: 0;">
                        <a-checkbox-group :disabled="declarationMethod != 'normalDeclare'" v-model:value="serviceItem"
                            @change="initServiceItem" style="width: 100%">
                            <a-checkbox v-for="item in serviceOptions" :key="item.id" :value="item.type">{{ item.name
                                }}</a-checkbox>
                        </a-checkbox-group>
                    </a-descriptions-item>
                </a-descriptions>
            </a-row>
            <a-row>
                <vue3-seamless-scroll :list="dataSource" step="0.4" class="seamless-warp" hover-stop="true" hover="true"
                    style="width: 100%;">
                    <a-table :columns="tableColumn" :data-source="dataSource" :loading="loading" :pagination="false"
                        :showHeader="false">
                        <template #bodyCell="{ column, record }">
                            <template v-if="column.dataIndex === 'avatar' && record.avatar">
                                <a-avatar style="cursor: pointer;" :size="60" :src="record.avatar" />
                            </template>
                            <div v-if="column.dataIndex === 'userName'">
                                <a-tooltip placement="top">
                                    <template #title>
                                        <div>{{ record.userName || '--' }}
                                            <a-divider type="vertical" />
                                            <a-tag color="green">{{ record.accountingLevel }} 级</a-tag>
                                            <a-tag color="blue">{{ record.workYears }} 年</a-tag>
                                        </div>
                                        <div class="mb-2 mt-2">
                                            <a-tag>{{ record.education_dictText || '--' }}</a-tag>
                                            <a-tag>{{ record.positionType_dictText || '--' }}</a-tag>
                                            <a-tag>{{ record.workAddressName }}</a-tag>
                                        </div>
                                        擅长行业: {{ record.industryName || '--' }}
                                    </template>
                                    <a-space :size="8" align="baseline">
                                        <h3>{{ record.userName || '--' }}</h3>
                                        <a-divider type="vertical" />
                                        <a-tag color="green">{{ record.accountingLevel }} 级</a-tag>
                                        <a-divider type="vertical" />
                                        <p>{{ record.education_dictText || '--' }}</p>
                                        <a-divider type="vertical" />
                                        <p>{{ record.positionType_dictText || '--' }}</p>
                                        <a-divider type="vertical" />
                                        <a-tag color="blue">{{ record.workYears }} 年</a-tag>
                                        <a-divider type="vertical" />
                                        <p>{{ record.workAddressName }}</p>
                                    </a-space>
                                    <div class="ellipsis"> 擅长行业: {{ record.industryName || '--' }}</div>
                                </a-tooltip>
                            </div>
                            <template v-if="column.dataIndex === 'accountingPrice'">
                                <template v-if="record.totalAmount != record.amount">
                                    <h3 style="color:red; text-decoration: line-through;">
                                        ¥ {{ Math.round(record.totalAmount / 100 / 12) }} / 月
                                    </h3>
                                    <h3 style="color:red;">
                                        (优惠价: ¥ {{ Math.round(record.amount / 100 / 12) }} / 月)
                                    </h3>
                                </template>
                                <template v-else>
                                    <h3 style="color:red;">
                                        ¥ {{ Math.round(record.totalAmount / 100 / 12) }} / 月
                                    </h3>
                                </template>
                            </template>
                            <template v-if="column.dataIndex === 'action'">
                                <a-button type="primary" @click="onClick">选择</a-button>
                            </template>
                        </template>
                    </a-table>
                </vue3-seamless-scroll>
            </a-row>
        </div>
    </div>
</template>

<script setup>
import { ref, onBeforeMount } from 'vue';
import { ExclamationCircleOutlined } from '@ant-design/icons-vue';
import { tableColumn, serviceOptions, plainOptions, imageList } from '../components/data'
import { getList } from '../api/api'
import { Modal } from 'ant-design-vue';
import { h } from 'vue';
const dataSource = ref([]);
const loading = ref(false)
const declarationMethod = ref('normalDeclare');
const serviceItem = ref(['daizhang_invoiced', 'accoun_issuetable', 'tax_declaration', 'income_taxsettlement', 'business_annualreport',]);
const pagination = ref({
    current: 1,
    pageSize: 10,
    pageSizeOptions: ['10', '20', '30'],
    showTotal: (total, range) => {
        return range[0] + "-" + range[1] + " 共" + total + "条"
    },
    showQuickJumper: true,
    showSizeChanger: true,
    total: 0
})
onBeforeMount(() => {
    initData();
})

function initData() {
    getTableList();
}

async function getTableList() {
    loading.value = true;
    let param = {
        declareType: declarationMethod.value,
        serviceItem: serviceItem.value.join(",")
    }
    const res = await getList(param)
    loading.value = false
    console.log("res", res)
    const { success, result } = res
    if (success) {
        dataSource.value = result.records
        pagination.value.total = result.total
    }
}

function onClick() {
    Modal.confirm({
        title: '请登录平台再进行后续操作',
        icon: h(ExclamationCircleOutlined),
        okText: '去登录',
        cancelText: '暂不登录',
        onOk() {
            console.log('OK');
            window.open('https://www.shangbian.net/v3/', '_blank')
        },
        onCancel() {
            console.log('Cancel');
        },
    });
}

function handleOk() {
    window.open('https://www.shangbian.net/v3/', '_blank')
}

// 申报方式点击事件
function onChange(e) {
    console.log('e :>> ', e);
    if (e.target.value == 'normalDeclare') {
        serviceItem.value = ["accoun_issuetable", "tax_declaration", "income_taxsettlement", "business_annualreport", "daizhang_invoiced"]
    } else if (e.target.value == 'zeroDeclare') {
        //零申报有费用 记账出表 纳税申报 基准价格100
        serviceItem.value = ["accoun_issuetable", "tax_declaration"]
    } else if (e.target.value == 'zeroDeclareNoCost') {
        //零申报无费用 纳税申报 基准价格50
        serviceItem.value = ["tax_declaration"]
    }
    getTableList()
}

function initServiceItem(e) {
    serviceItem.value = e
    getTableList()
}
</script>

<style scoped>
.jeecg-basic-header {
    margin: 1rem;
}

.seamless-warp {
    padding: 2rem;
    height: 75vh;
    overflow: hidden;
    width: 100%;
}

.a-image {
    cursor: pointer !important;
}
</style>