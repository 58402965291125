<template>
    <a-modal v-model:visible="open" :title="title" :footer="null" :width="500" :maskClosable="false">
        <a-form model="model" name="basic" autocomplete="off">
            <a-form-item label="手机号" name="username">
                <a-input v-model:value="model.phone" />
            </a-form-item>

            <!-- <a-form-item label="验证码" name="password">
                <a-input-group compact>
                    <a-input v-model:value="model.code" style="width: calc(100% - 200px)" />
                    <a-button v-if="!countDownIng" type="primary" @click="getCode" :loading="loading">获取验证码</a-button>
                    <a-button v-if="countDownIng" :disabled="true" type="primary">{{ countDownTime }}s 后获取验证码</a-button>
                </a-input-group>
            </a-form-item> -->

            <a-form-item style=" text-align: center;">
                <a-button type="primary" html-type="submit" style="width: 50%;" @click="handleOk">
                    立即领取
                </a-button>
            </a-form-item>
        </a-form>
    </a-modal>
</template>
<script setup>
import { ref, computed, onMounted } from "vue";
import { message } from 'ant-design-vue';
import { getSmsCode, receiveActive } from '@/api/api.js'
const open = ref(false)
const title = ref('')
const id = ref('')
const model = ref({
    phone: '',
    code: ''
});
const countDownTime = ref(60)
const timer = ref(null)
const countDownIng = ref(false)
const loading = ref(false)
function showModal(value) {
    title.value = value.title
    id.value = value.id
    open.value = true
}

async function handleOk() {
    if (!model.value.phone) {
        return message.warning('请输入手机号');
    }
    if (!phoneNumberStyle()) {
        return message.warning('手机号格式有误');
    }
    let params = { 
        id: id.value,
        phone: model.value.phone
    }
    const res = await receiveActive(params)
    console.log(res, "ress")
    if (res.success) {
        message.success('领取成功')
        setTimeout(() => {
            open.value = false
        }, 1000)
    } else {
        message.error(res.message)
    }
}

function phoneNumberStyle() {
    let reg = /^1[3456789]\d{9}$/;
    if (!reg.test(model.value.phone)) {
        return false;
    }
    return true;
}

async function getCode() {
    loading.value = true
    if (!model.value.phone) {
        return message.warning('请输入手机号');
    }
    if (!phoneNumberStyle()) {
        return message.warning('手机号格式有误');
    }
    let params = {
        mobile: model.value.phone,
        smsmode: '2'
    }
    const res = await getSmsCode(params);
    console.log(res, "res")
    if (res.success) {
        setTimerOut();
    } else {
        message.error(res.message);
    }

}

function setTimerOut() {
    let startTime = localStorage.getItem('startTimeLogin');
    let nowTime = new Date().getTime();
    if (startTime) {
        let surplus = 60 - parseInt((nowTime - startTime) / 1000, 10);
        countDownTime.value = surplus <= 0 ? 0 : surplus;
    } else {
        countDownTime.value = 60;
        localStorage.setItem('startTimeLogin', nowTime);
    }
    timer.value = setInterval(() => {
        countDownTime.value--;
        countDownIng.value = true;
        loading.value = false
        if (countDownTime.value <= 0) {
            localStorage.removeItem('startTimeLogin');
            clearInterval(timer.value);
            countDownTime.value = 60;
            countDownIng.value = false;
        }
    }, 1000);
}

onMounted(() => {
    let sendEndTime = localStorage.getItem('startTimeLogin');
    if (sendEndTime) {
        countDownIng.value = true;
        setTimerOut()
    }
})

defineExpose({
    showModal
})
</script>

<style></style>