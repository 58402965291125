<template>
    <div class="home-content-fun">
        <div class="home-content-fun-title">
            <span style="font-size: 36px; font-weight: 600; color: rgb(2, 2, 2);">数字化报销系统</span>
            <h4 style="font-size: 24px; color: rgb(136, 136, 136); margin-top: 0.4rem;">企业费控，轻松搞定！</h4>
        </div>
        <div class="home-content-fun-list">
            <a-row>
                <a-col :span="17" style="padding-right: 0.3rem;">
                    <a-carousel ref="carousel" style="height: 100%;" :dots="false">
                        <template v-for="(item, index) in reimbursementCarouselItem">
                            <a-image :src="item.imgUrl">
                                <template #previewMask>
                                    <a-space :size="3">
                                        <EyeOutlined />
                                        <span>预览</span>
                                    </a-space>
                                </template>
                            </a-image>
                        </template>
                    </a-carousel>
                </a-col>
                <a-col :span="7" style="padding-right: 0.3rem;">
                    <template v-for="(item, index) in reimbursementCarouselItem">
                        <a-row :class="currentIndex === index ? 'list-item-hoverd' : 'list-item'"
                            @mouseover="onClick(index)">
                            <a-col class="list-item-img" :span="6">
                                <img :src="item.sortUrl" style="width: 2.3rem;" />
                            </a-col>
                            <a-col class="list-item-title" :span="18">
                                <span style="">{{ item.title }}</span>
                            </a-col>
                        </a-row>
                    </template>
                </a-col>
            </a-row>
        </div>
    </div>
</template>

<script setup>
import { EyeOutlined } from '@ant-design/icons-vue';
import { ref } from 'vue';
import { reimbursementCarouselItem } from '../data'
const carousel = ref(null);
const currentIndex = ref(0);

const onClick = (index) => {
    currentIndex.value = index
    carousel.value.goTo(index, true)
}
</script>